import http from '@/config/http-common';
import File from '@/types/File';

class AgencyDataService {
  static getOrdered(token: string|null, $file: File): Promise<any> {
    return http.get(`/agencies?file=${$file.pid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export default AgencyDataService;
