import http from '@/config/http-common';
import File from '@/types/File';

class TimeSlotDataService {
  static getDatesFilteredBy(token: string|null, agency: string): Promise<any> {
    return http.get(`/agencies/${agency}/timeslots/available_dates`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getTimesFilteredBy(token: string|null, agency: string, date: string): Promise<any> {
    return http.get(`/agencies/${agency}/timeslots/available_times?date=${date}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static book(token: string|null, agency: string, file: File, timeslot: string, recaptcha: string): Promise<any> {
    return http.post(`/agencies/${agency}/timeslots/book`, {
      file: file.pid,
      timeslot,
      recaptcha,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export default TimeSlotDataService;
