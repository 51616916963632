
import { defineComponent } from 'vue';
import globals from '../../helpers/globals';
import FormButton from '../../components/forms/Button.vue';
import FormSelect from '../../components/forms/Select.vue';
import DocumentDataHelper from '../../helpers/DocumentDataHelper';
import TimeSlot from '../../types/TimeSlot';
import DateSlot from '../../types/DateSlot';
import AgencyDataService from '../../services/AgencyDataService';
import TimeSlotDataService from '../../services/TimeSlotDataService';
import FileDataHelper from '../../helpers/FileDataHelper';

export default defineComponent({
  name: 'RDV',
  mixins: [
    globals,
  ],
  components: {
    FormButton,
    FormSelect,
  },
  data() {
    return {
      serverError: null as any,
      agencies: {
        list: {} as any,
        default: {},
        selected: '' as string,
      },
      schedule: {
        dates: [] as DateSlot[],
        selectedDate: '' as string,
        times: [] as TimeSlot[],
        selectedTime: '' as string,
      },
    };
  },
  created() {
    const vm = this;

    /* LOAD DATA WHEN COMPONENT IS CREATED */
    this.$store.dispatch('isLoading', true);

    DocumentDataHelper.prepareComponentData(
      this,
      this.$route.params.file as string,
      (response: any) => {
        vm.load((data: any) => {
          // Redirect user of he didnt complete RDV step
          console.log(data);
          if (data.documents.length !== vm.$store.state.documentTypes.length) {
            vm.$router.push({
              name: 'DocumentUpload',
              params: {
                file: vm.$route.params.file,
              },
            });

            vm.$notify({
              text: 'Veuillez vous assurer que vous avez téléversé tous les documents requis.',
              duration: 8000,
              ignoreDuplicates: true,
              type: 'error',
            });
          }

          const token = this.getAccessToken();
          AgencyDataService.getOrdered(token, vm.$store.state.selected.file)
            .then((responses: any) => {
              vm.agencies.list = {};

              Object.keys(responses.data.data).forEach((key: any) => {
                vm.agencies.list[responses.data.data[key].pid as string] = `${responses.data.data[key].code} - ${responses.data.data[key].name}`;
              });

              vm.agencies.default = responses.data.data.default;

              vm.init();
            })
            .catch((e: Error) => { /* */ });
        });
      },
      (e: Error) => { /* */ },
    );
  },
  mounted() {
    /* Scroll page to top (case of mobile phones) */
    this.scrollToTop();
  },
  methods: {
    /* COMPONENT INITIALIZER */
    init() {
      /* FLAG STEP AS UNCOMPLETED */
      this.setStepAsCompleted(this, false);

      /* MOVE PROGRESS BAR TO VALUE */
      this.progress(this);

      /* SHOW SIDE AND NAV BAR IF USER FALLS DIRECTLY INTO CURRENT COMPONENT */
      this.$store.dispatch('toggleProgressBar', true);
      this.$store.dispatch('toggleSideBar', true);

      /* STOP LOADING */
      this.$store.dispatch('isLoading', false);
    },
    /* LOAD DATA THROUGH APIS */
    load(callback: any) {
      FileDataHelper.prepareComponentData(
        this,
        this.$route.params.file as string,
        callback,
        (e: Error) => { /* */ },
      );
    },
    refreshListOfDays() {
      const vm = this;
      const token = this.getAccessToken();

      if (vm.agencies.selected) {
        TimeSlotDataService.getDatesFilteredBy(token, vm.agencies.selected)
          .then((response: any) => {
            vm.schedule.dates = response.data.data;
            this.schedule.selectedDate = '';
            this.schedule.selectedTime = '';
          })
          .catch((e: Error) => { /* */ });
      }
    },
    refreshListOfTimes(date: string) {
      const vm = this;
      const token = this.getAccessToken();

      this.schedule.selectedDate = date;
      this.schedule.selectedTime = '';

      TimeSlotDataService.getTimesFilteredBy(token, vm.agencies.selected, date)
        .then((response: any) => {
          vm.schedule.times = response.data.data;
        })
        .catch((e: Error) => { /* */ });
    },
    selectTime(timePid: string) {
      this.schedule.selectedTime = timePid;
    },
    goToNextStep(event: any) {
      event.preventDefault();

      const vm = this;
      const token = this.getAccessToken();
      this.$store.dispatch('isLoading', true);

      // Load recaptcha token
      this.recaptcha(this)
        .then((resp: any) => {
          /* TODO Make some actions before moving to email confirmation */

          // this.$store.dispatch('setRDVAsCompleted', false);
          vm.serverError = null;

          TimeSlotDataService.book(token, vm.agencies.selected, vm.$store.state.selected.file, vm.schedule.selectedTime, vm.$store.state.recaptcha)
            .then((response: any) => {
              this.goToNextScreen(this, {
                file: this.$store.state.selected.file.pid,
              });
            })
            .catch((e: any) => { /* See Global handler http-common.ts */ });
        });
    },
    disabledSubmitButton() {
      return this.agencies.selected === '' || this.schedule.selectedDate === '' || this.schedule.selectedTime === '';
    },
  },
});
